<template>
    <v-app>
<v-container>
  <v-row>
        <v-container>
            <v-row class="mt-1">
                <v-col></v-col>
                <v-col cols="6">

        <v-card min-width="500px" class="mx-auto mt-5">
          <v-card-title class="pt-6 text-subtitle-1"> 実施済みです。<br>再度実施する場合は管理者へご連絡ください。 </v-card-title>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="ok" > OK </v-btn>
          </v-card-actions>
        </v-card>

                </v-col>
                <v-col></v-col>
            </v-row>
        </v-container>
    
  </v-row>
</v-container>

  </v-app>
</template>

<style>
.v-btn--active.no-active::before {
  opacity: 0 ! important;
}
</style>

<script>

export default {
    data: function() {
        return {
          dialog: false,
        }
    },
    created: async function() {
        console.log("created");

    },
    methods: {
        ok() {
            console.log("chushi");
            this.logout();
        },

        logout() {
            console.log("logout");
            this.$store.commit('updateIdToken', null);
            this.$router.push('/');
        },

    },
}


</script>
