import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        idToken: null,
        testCode: null,
        userCode: null,
        userId: null,
        kanriId: null,
        testResult: null
    },
    getters: {
        idToken: state => state.idToken,
        testCode: state => state.testCode,
        userCode: state => state.userCode,
        userId: state => state.userId,
        kanriId: state => state.kanriId,
        testResult: state => state.testResult,
    },
    mutations: {
        updateIdToken(state,idToken) {
            state.idToken = idToken;
        },
        updateTestCode(state,testCode) {
            state.testCode = testCode;
        },
        updateUserCode(state,userCode) {
            state.userCode = userCode;
        },
        updateUserId(state,userId) {
            state.userId = userId;
        },
        updateKanriId(state,kanriId) {
            state.kanriId = kanriId;
        },
        updateTestResult(state,testResult) {
            state.testResult = testResult;
        },
    },
});