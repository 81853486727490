<template>
  <v-app>
    <!--
    <v-app-bar >
      <router-link to="/" class="header--link">トップ</router-link> 説明ページ用にとりあえず残しておく 
      <router-link to="/about" class="header--link" v-if="isLoggedIn">詳細</router-link>
      <router-link to="/login" class="header--link" v-if="!isLoggedIn">ログイン</router-link>
    </v-app-bar>
-->
    <router-view></router-view>
  </v-app>
</template>

<script>
const ANSWER = { YES: 1, NO: 0 }

export default {
  data: function() {
        return {
            questions: [
                {text:"v-on:click の省略記法は #click である", answer: ANSWER.NO },
            ],
            currentIndex: 0,
            yourAnswers: []
        }
  },
  computed: {
    currentQuestion: function() {
      return this.questions[this.currentIndex]
    },
    isLoggedIn() {
      return this.$store.getters.idToken !== null ;
    }
  }
}
</script>


<style scoped>
.header {
  text-align: center;
  display: flex;
  justify-content: center;
}
.header--link {
  padding: 10px;
}
</style>
