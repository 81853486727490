import Vue from 'vue';
import Router from 'vue-router';
import Login from './pages/Login.vue';
import Check from './pages/Check.vue';
import Result from './pages/Result.vue';
import User from './pages/User.vue';
import Cancel from './pages/Cancel.vue';
import store from './store';

Vue.use(Router);

export default new Router({
   mode: "history",
   routes: [
       { path: '/', component: Login },
       { path: '/user', component: User, props: true,
         beforeEnter(to, from, next ) {
             if (store.getters.idToken) {
                 next();
             }else{
                next("/"); // なければそのままLoginに
             }
         },
       },
       { path: '/check', component: Check, props: true,
         beforeEnter( to, from, next) {
             if ( store.getters.idToken ) {
                 next();
             }else{
                next("/"); // なければそのままLoginに
             }
         },
       },
       { path: '/result', component: Result, props: true,
         beforeEnter( to, from, next ) {
             if (store.getters.idToken) {
                 next();
             } else {
                next("/"); // なければそのままLoginに
             }
         },
       },
       { path: '/cancel', component: Cancel, props: true,
         beforeEnter(to, from, next ) {
             if (store.getters.idToken) {
                 next();
             }else{
                next("/"); // なければそのままLoginに
             }
         },
       },
   ] 
});

