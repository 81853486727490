<template>
    <v-app>
      <v-dialog v-model="dialog" persistent max-width="290" >

        <template v-slot:activator="{ on, attrs }">

        <v-window>
          <v-toolbar color="indigo" dark>
            <v-btn text v-bind="attrs" v-on="on"><v-icon dark left>mdi-alert-circle-outline</v-icon>キャンセル</v-btn>
<!--            
<v-btn color="primary" @click="sendAnswer">確定</v-btn>
-->
          </v-toolbar>
        </v-window>
        </template>

        <v-card>
          <v-card-title class="pt-6 text-subtitle-1"> 入力を中止しますか？ </v-card-title>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancel" > はい </v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false" > いいえ </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>

<div class="text-center">
<v-overlay :value="overlay">
  <v-progress-circular indeterminate size="64"></v-progress-circular>
</v-overlay>
</div>

<v-container>
  <v-row>
    <v-col cols="12">
          <v-card class="mt-3 mx-auto" min-width="360">

              <v-container>
              <v-row>
                <v-col cols="12">

                  <v-window v-model="onboarding">
                    <v-window-item v-for="question in questions" :key="question.id">

                      <p class="pt-2 text-body-1 text-sm-h6 text-md-hd5 text-center" v-if="question">{{question.fieldData.設問no}}<br>{{question.fieldData.string}}</p>

                      <v-btn-toggle class="d-flex justify-center" v-model="answer">
                        <v-row class="pb-3" v-if="question">
                          <v-col align="center" cols="12"><v-btn outlined active-class="no-active" @click="doAnswer(1)" v-bind:class="{'blue lighten-3':question.fieldData.code==1, 'grey lighten-5':question.fieldData.code!=1 }" height="50px" width="180px" >{{ currentChoices[0].設問_2_選択肢_string }}</v-btn></v-col>
                          <v-col align="center" cols="12"><v-btn outlined active-class="no-active" @click="doAnswer(2)" v-bind:class="{'blue lighten-3':question.fieldData.code==2, 'grey lighten-5':question.fieldData.code!=2 }" height="50px" width="180px" >{{ currentChoices[1].設問_2_選択肢_string }}</v-btn></v-col>
                          <v-col align="center" cols="12"><v-btn outlined active-class="no-active" @click="doAnswer(3)" v-bind:class="{'blue lighten-3':question.fieldData.code==3, 'grey lighten-5':question.fieldData.code!=3 }" height="50px" width="180px" >{{ currentChoices[2].設問_2_選択肢_string }}</v-btn></v-col>
                          <v-col align="center" cols="12"><v-btn outlined active-class="no-active" @click="doAnswer(4)" v-bind:class="{'blue lighten-3':question.fieldData.code==4, 'grey lighten-5':question.fieldData.code!=4 }" height="50px" width="180px" >{{ currentChoices[3].設問_2_選択肢_string }}</v-btn></v-col>
                        </v-row>
                      </v-btn-toggle>

                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-container>

           <v-divider class="py-1"></v-divider>

            <v-card-actions>
              <v-btn color="grey darken-3" text @click="prev" >戻る</v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="this.isLastQuestion" color="primary" depressed @click="sendAnswer" >確定</v-btn>
              <v-btn v-else color="primary" depressed @click="next" >次へ</v-btn>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-app>
</template>

<style>
.v-btn--active.no-active::before {
  opacity: 0 ! important;
}
</style>

<script>
import axios from "axios";
import UUID from "uuidjs";

// const url = "https://iryouhoujinnikikai.account.filemaker-cloud.com";
// const url = "https://www.real-works.info";
// const url = "https://192.168.151.1";
const url = "https://www.niki-hp.work";

const login_headers = {
              "Content-Type": "application/json",
              "Authorization": "Basic YXBpX3VzZXI6RmRwZzlyNzcjQ3lJZUE1M0A=",
}

const headers = {
              "Content-Type": "application/json",
}

const checkID = UUID.generate();
console.log(checkID)

export default {
    data: function() {
        return {
          dialog: false,
          overlay: false,     // 送信後のローダー表示制御
          token: "",
          code: "",
          info: null,
          questions: [],      // 問題文の配列
          length: 0,
          onboarding: 0,      // 現在表示している問題文のインデックス
          choices: [],


          yourAnswers: [],    // 自分の回答を格納する配列
          answer: "",
          userID: "",
          kanriID: "",

        }
    },
    created: async function() {
        console.log("created");

        await this.dbLogin();

        await this.getKanriID();
        await this.getUserID();

        await this.getQuestion();
        console.log(this.questions)

        this.dbLogout();

    },
    methods: {
        cancel() {
            console.log("cancel");
            this.dialog = false;
            this.logout();
        },

        next() {
            this.onboarding = this.onboarding + 1 === this.length ? this.onboarding : this.onboarding + 1 ;
            // 値を戻しておく
            this.answer = this.currentQuestion.fieldData.code ? this.currentQuestion.fieldData.code - 1 : "" ;

        },

        prev() {
            this.onboarding = this.onboarding - 1 < 0 ? 0 : this.onboarding - 1 ;
            // 値を戻しておく
            this.answer = this.currentQuestion.fieldData.code ? this.currentQuestion.fieldData.code - 1 : "" ;
        },
        
        logout() {
            console.log("logout");
            this.$store.commit('updateIdToken', null);
            this.$router.push('/');
        },

        doAnswer: function(code) {
            this.currentQuestion.fieldData.code=code;
            this.next();
        },

        sendAnswer: async function() {
          console.log("sendAnswer");
          this.overlay=true;

          await this.dbLogin();
          await this.addRecord();
          await this.getResult();
          this.dbLogout()

          this.$router.push('/result');
        },

        // axios
        get() {
            axios
            .get(
              url + "/fmi/data/v2/productInfo" 
            )
            .then(response => {
              console.log( response )
            });
        },
        dbLogin: async function() {
            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/sessions/", {}, {
                headers: login_headers
              }
            )
            .then(response => {
              this.code = response.data.messages[0].code;
              this.token = response.data.response.token;
              console.log( this.token )

            });
        },
        dbLogout() {

            const logout_headers = headers 

            axios
            .delete(
              url + "/fmi/data/v2/databases/StressCheck/sessions/" + this.token, {}, {
                headers: logout_headers
              }
            )
            .then(response => {
              console.log( response.data.messages[0] )
            });
        },

        getKanriID: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;

            console.log("getters1: " + this.$store.getters.testCode);
            const query = { "query" : [{ "testCode" : "=" + this.$store.getters.testCode }]};

            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_kanri/_find", query, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response.data.response.data[0].fieldData.ID );
                this.kanriID = response.data.response.data[0].fieldData.ID;
                this.$store.commit('updateKanriId', this.kanriID );

                console.log( "kanriID=" + this.kanriID );
            })
            .catch(error=> {
                console.log(error);
                this.$store.commit('updateIdToken', "" );
                this.$router.push('/');
            });
        },
        getUserID: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;

            console.log("getters2: " + this.$store.getters.userCode);
            const query = { 
              "query" : [
                { "fk実施管理ID" : "=" + this.kanriID , "従業員番号" : "=" + this.$store.getters.userCode }
              ]
            };

            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_person/_find", query, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response.data.response.data[0].fieldData.ID );
                this.userID = response.data.response.data[0].fieldData.ID;
                this.jisshi = response.data.response.data[0].fieldData.実施状況;
                this.$store.commit('updateUserId', this.userID );
                console.log( "userID=" + this.userID );
            })
            .catch(error=> {
                console.log(error);
                this.$store.commit('updateIdToken', "" );
                this.$router.push('/');

            });
        },
        getQuestion: async function() {
            headers.Authorization = "Bearer " + this.token
            const find_header = headers

            const query = { "query" : [{ "fkテストID" : "=12EFDE8B-8EE1-49E7-8302-5BFAC9839097" }] }

            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_setsumon/_find", query, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response )
                this.info = response.data
                this.questions = response.data.response.data
                this.length = this.questions.length

            });
        },

        getChoices: async function( type ) {
            headers.Authorization = "Bearer " + this.token
            const find_header = headers

            const query = { "query" : [{ "fkテストID" : "=12EFDE8B-8EE1-49E7-8302-5BFAC9839097", "type" : type }] }

            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_choices/_find", query, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response )
            });
        },

        getResult: async function() {
            headers.Authorization = "Bearer " + this.token
            const find_header = headers

            const query = { "query" : [{ "fk受検者ID" : "=" + this.userID }] }

                console.log( "getResult " + query )
            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_result/_find", query, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response )
//                this.info = response.data
//                this.questions = response.data.response.data
//                this.length = this.questions.length
                this.$store.commit('updateTestResult', response.data.response.data[0] );
            });
        },

        addRecord: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;
            const persistenceID = "30AE2CBA65A9F11A0C5F1CCFC206D606";
            const testID = "12EFDE8B-8EE1-49E7-8302-5BFAC9839097";

            const param = { 
              "script.prerequest" : "deleteKaito",
              "script.prerequest.param" : this.kanriID + "|" + this.userID + "|" + persistenceID + "|",
              "script.presort" : "convertData",
              "script.presort.param" : this.kanriID + "|" + this.userID + "|" + persistenceID + "|",
              "fieldData" : {
                "persistenceID" : persistenceID ,
                "personID" : this.userID ,
                "kanriID" : this.kanriID ,
                "testID" : testID ,
//                "answer" : "1^2|2^3|3^4|4^1|5^2|" 
                "answer" : ""
              } 
            }

            for ( let i=0 ;  i < this.length ; i++ ) {
              let no = this.questions[i].fieldData.設問no ? this.questions[i].fieldData.設問no : "null"
              let code = this.questions[i].fieldData.code ? this.questions[i].fieldData.code : ""
              param.fieldData.answer = param.fieldData.answer + no + "^" + code + "|"
            }
            console.log(param.fieldData.answer)

          await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/WKdata/records", param, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response )
            });
        },
    },
    computed: {
        // 現在表示している問題文
        currentQuestion: function() {
            return this.questions[this.onboarding]
        },
        // 一旦文字列にして変換を行い、JSONに戻す
        // :があると指定できないため
        currentChoices: function() {
            const tempString = JSON.stringify( this.questions[this.onboarding].portalData.設問_2_選択肢 );
            const resultValue = JSON.parse( tempString.replace(/::/g,"_") ); 
            return resultValue ;
        },
        isLastQuestion: function() {
            return this.onboarding === ( this.length - 1 )
        },

    },
}


</script>
