<template>
   <v-app>
        <v-container>
        <v-row>
        <v-card width="360px" class="mx-auto mt-5">
            <v-card-title>
                <h1 class="display-1">判定結果</h1>
            </v-card-title>
            <v-card-text>
                <v-simple-table class="center">
                    <template v-slot:default>
                    <tbody>
                <tr><th width="170px">判定</th><td align="center" width="170px"><div v-if="hantei === 'NC'">問題ありません</div><div v-else>高ストレス状態です</div></td></tr>
                <tr><th>A．ストレスの要因</th><td align="center">{{ pointA }}</td></tr>
                <tr><th>B．心身の自覚症状</th><td align="center">{{ pointB }}</td></tr>
                <tr><th>C．周囲の支援</th><td align="center">{{ pointCD }}</td></tr>
                <tr><th>合計</th><td align="center">{{ total }}</td></tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        </v-row>
        <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="8">
                <v-simple-table class="center">
                <tbody>
                <tr>
                    <td width="300px">より詳しい結果の送付を希望されますか</td>
                    <td width="200px">
                        <v-radio-group v-model="question1" row >
                            <v-radio label="はい" value="1" ></v-radio>
                            <v-radio label="いいえ" value="0" ></v-radio>
                        </v-radio-group>
                    </td>
                </tr>
                <tr>
                    <td width="300px">判定結果について職場に開示してよいですか</td>
                    <td width="200px">
                        <v-radio-group v-model="question2" row >
                            <v-radio label="はい" value="1" ></v-radio>
                            <v-radio label="いいえ" value="0" ></v-radio>
                        </v-radio-group>
                    </td>
                </tr>
                <tr v-if="hantei != 'NC'">
                    <td width="300px">産業医の面接を希望されますか</td>
                    <td width="200px">
                        <v-radio-group v-model="question3" row >
                            <v-radio label="はい" value="1" ></v-radio>
                            <v-radio label="いいえ" value="0" ></v-radio>
                        </v-radio-group>
                    </td>
                </tr>
                </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="center" class="mt-4"><v-btn height="50px" width="120px" @click="sendAnswer">終了</v-btn></v-col>
        </v-row>
        </v-container>
    </v-app>
</template>

<script>
import axios from "axios";

// const url = "https://iryouhoujinnikikai.account.filemaker-cloud.com";
// const url = "https://www.real-works.info";
// const url = "https://192.168.151.1";
const url = "https://www.niki-hp.work";

const login_headers = {
              "Content-Type": "application/json",
              "Authorization": "Basic YXBpX3VzZXI6RmRwZzlyNzcjQ3lJZUE1M0A=",
}

const headers = {
              "Content-Type": "application/json",
}


export default {
    data() {
        return {
            testResult: "",
            hantei: "",
            pointA: "",
            pointB: "",
            pointCD: "",
            total: "",
            question1:"",
            question2:"",
            question3:"",

            userID: "",
            kanriID: "",
            token: "",
            code: "",
        }
    },
    created: async function() {
        console.log("created");

        this.testResult = this.$store.getters.testResult;
        this.hantei = this.testResult.fieldData.c評価用_高ストレス判定;
        this.pointA = this.testResult.fieldData.c評価用_A項目;
        this.pointB = this.testResult.fieldData.c評価用_B項目;
        this.pointCD = this.testResult.fieldData.c評価用_CD項目;
        this.total = this.testResult.fieldData.c評価用_全合計点数;
        
        this.kanriID = this.$store.getters.kanriId;
        this.userID = this.$store.getters.userId;

        console.log( "userID = " + this.userID )
    },
    methods: {
        logout() {
            this.$store.commit('updateIdToken', null);
            this.$router.push('/');
        },

        sendAnswer: async function() {
          console.log("sendAnswer");
          this.overlay=true;

          await this.dbLogin();
          await this.addRecord();
          this.dbLogout()

          this.logout();
        },

        // axios
        get() {
            axios
            .get(
              url + "/fmi/data/v2/productInfo" 
            )
            .then(response => {
              console.log( response )
            });
        },

        dbLogin: async function() {
            await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/sessions/", {}, {
                headers: login_headers
              }
            )
            .then(response => {
              this.code = response.data.messages[0].code;
              this.token = response.data.response.token;
              console.log( this.token )

            });
        },
        dbLogout() {
            const logout_headers = headers 

            axios
            .delete(
              url + "/fmi/data/v2/databases/StressCheck/sessions/" + this.token, {}, {
                headers: logout_headers
              }
            )
            .then(response => {
              console.log( response.data.messages[0] )
            });
        },
        addRecord: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;
            const persistenceID = "30AE2CBA65A9F11A0C5F1CCFC206D606";

            const param = { 
              "script.prerequest" : "deleteShuryo",
              "script.prerequest.param" : this.kanriID + "|" + this.userID + "|" + persistenceID + "|",
              "fieldData" : {
                "fk受検者ID" : this.userID ,
                "fk実施管理ID" : this.kanriID ,
                "結果印刷" : this.question1,
                "開示" : this.question2,
                "面接希望" : this.question3
              } 
            }

            console.log(param.fieldData)
          await axios
            .post(
              url + "/fmi/data/v2/databases/StressCheck/layouts/api_shuryo/records", param, {
                headers: find_header 
              }
            )
            .then(response => {
                console.log( response )
            });
        },
    }

}

</script>