<template>
    <v-app>
        <v-card min-width="360px" class="mx-auto mt-5">
            <v-container v-if="userName">
            <v-row class="mt-5">
                <v-col class="text-h6 text-md-h6 text-center">あなたのお名前は</v-col>
            </v-row>
            <v-row>
                <v-col class="text-h6 text-md-h6 text-center">{{userName}} 様</v-col>
            </v-row>
            <v-row class="mb-5">
                <v-col class="text-h6 text-md-h6 text-center">でよろしいですか</v-col>
            </v-row>
            <v-row>
                <v-col cols="6" align="center"><v-btn color="gray lighten-4" height="40px" width="100px" @click="answerYes">はい</v-btn></v-col>
                <v-col cols="6" align="center"><v-btn color="gray lighten-4" height="40px" width="100px" @click="answerNo">いいえ</v-btn></v-col>
            </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>

<script>
import axios from "axios";

//const url = "https://iryouhoujinnikikai.account.filemaker-cloud.com";
//const url = "https://www.real-works.info";
//const url = "https://192.168.151.1";
const url = "https://www.niki-hp.work";

const login_headers = {
              "Content-Type": "application/json",
              "Authorization": "Basic YXBpX3VzZXI6RmRwZzlyNzcjQ3lJZUE1M0A=",
}

const headers = {
              "Content-Type": "application/json",
}

export default {
    data: function() {
        return {
            token: "",
            code: "",

            testCode: "",   // 入力されるチェックコード
            userCode: "",   // 入力される職員番号
            kanriID: "",    // 管理ID
            userID: "",     // 受検者ID

            userName: "",     // 受検者の名前
            jisshi: "",
        }
    },
    /*
    mounted() {
        console.log("mounted")
        if (this.$store.getters.idToken) {
            this.$router.push("/check")
        }else{
            this.$store.commit('updateIdToken', "" );
            this.$store.commit('userCode', "" );
            this.$store.commit('kanriID', "" );
            this.$router.push("/")
        }
    },
    */
    created: async function() {
        console.log("User created");

        await this.dbLogin();
        await this.getKanriID();
        await this.getUserID();
        this.dbLogout();

    },
    methods: {
        // ======== answerYes ============
        answerYes: function() {
            if ( this.jisshi == '済' ) {
                this.$router.push('/cancel');
            }else{
                this.$router.push('/check');
            }
        },
        // ======== answerNo ============
        answerNo: function() {
                this.$store.commit('updateIdToken', "" );
                this.$store.commit('userCode', "" );
                this.$store.commit('kanriID', "" );
                this.$router.push('/');
        },
        // ======== dbLogin ============
        dbLogin: async function() {
            await axios
            .post( url + "/fmi/data/v2/databases/StressCheck/sessions/", {}, { headers: login_headers })
            .then(response => {
              this.code = response.data.messages[0].code;
              this.token = response.data.response.token;
              console.log( this.token )
            });
        },

        // ======== dbLogout ============
        dbLogout() {

            const logout_headers = headers 

            axios
            .delete( url + "/fmi/data/v2/databases/StressCheck/sessions/" + this.token, {}, { headers: logout_headers })
            .then(response => {
              console.log( response.data.messages[0] )
            });
        },

        // ======== getKanriID ============
        getKanriID: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;

            console.log("getters_testCode: " + this.$store.getters.testCode);
            const query = { "query" : [{ "testCode" : "=" + this.$store.getters.testCode }]};

            await axios
            .post( url + "/fmi/data/v2/databases/StressCheck/layouts/api_kanri/_find", query, { headers: find_header })
            .then(response => {
                console.log( response.data.response.data[0].fieldData.ID );
                this.kanriID = response.data.response.data[0].fieldData.ID;
                console.log( "kanriID=" + this.kanriID );
            })
            .catch(error=> {
                console.log(error);
                this.$store.commit('updateIdToken', "" );
                this.$router.push('/');
            });
        },

        // ======== getUserID ============
        getUserID: async function() {
            headers.Authorization = "Bearer " + this.token;
            const find_header = headers;

            console.log("getters_userCode: " + this.$store.getters.userCode);
            const query = { 
              "query" : [
                { "fk実施管理ID" : "=" + this.kanriID , "従業員番号" : "=" + this.$store.getters.userCode }
              ]
            };

            await axios
            .post( url + "/fmi/data/v2/databases/StressCheck/layouts/api_person/_find", query, { headers: find_header })
            .then(response => {
                console.log( response.data.response.data[0].fieldData );
                this.userID = response.data.response.data[0].fieldData.ID;
                this.userName = response.data.response.data[0].fieldData.i氏名;
                this.jisshi = response.data.response.data[0].fieldData.実施状況;
                console.log( "userID=" + this.userID );
                console.log( "jisshi=" + this.jisshi );
            })
            .catch(error=> {
                console.log(error);
                this.$store.commit('updateIdToken', "" );
                this.$router.push('/');

            });
        },
    }

}
</script>